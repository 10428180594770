import { useContext } from "react";
import { Button, Col, Container, Row, Toast } from "reactstrap";
import VCard from 'vcard-creator'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

import { toFilename } from "@helpers/card-formatter";
import { BusinesCardInDB, SupportedSocials, UserContextType } from "@types";
import { ICardSocialMedia, NavbarDefault } from "components";
import { UserContext } from "context";

import 'scss/css/style.css';
import 'styles/cardPage.css';
import { useHref } from "react-router-dom";

type CardPageProps = {
    businessCard: BusinesCardInDB;
}

const CardPage = ({ businessCard }: CardPageProps): JSX.Element => {
    const { darkMode } = useContext(UserContext) as UserContextType;

    const downloadVCard = () => {

        var vCard="BEGIN:VCARD\nVERSION:3.0\nREV:2022-12-30T05:56:13Z\nN;CHARSET=utf-8:"+businessCard.name
            +"\nEMAIL;INTERNET:"+businessCard.email
            +"\nTEL:+"+businessCard.socials.whatsapp?.slice(businessCard.socials.whatsapp.length -12, businessCard.socials.whatsapp.length)
            +"\nPHOTO;ENCODING=b;TYPE=JPEG:"+businessCard.photo
            +"\nEND:VCARD";

        var blob = new Blob([vCard], {type: 'text/vcard'}); //no se que haga 'text/vcard;charset=utf-8' pero parece ser igual
        var downloadUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = downloadUrl;
        a.download = businessCard.name+".vcf";
        document.body.appendChild(a);
        a.click();   
    }

    const mostrarImage = () => {   

        const myVCard = new VCard();
        myVCard.addPhoto(businessCard.photo);

        console.log(myVCard.toString());


    }

    return (
        <Container id='card-page'
            className={`
                pt-4 g-0
                ${darkMode ? 'bg-dark-dark' : ''}
                overflow-hidden
            `}
            fluid
        >
            <NavbarDefault />
            <Container
                id='card-page-content'
                className='overflow-hidden under-navbar'
            >
                <Row
                    id='row-top'
                    className={`
                        ${darkMode ? 'text-light' : ''}
                        justify-content-evenly align-items-center
                        mx-auto mb-3
                    `}
                >
                    <Col xs={6}
                        id='col-info'
                    >
                        <span
                            className='fs-1 fw-bold'
                        >
                            {businessCard.companyName}
                        </span>
                        <div className='fs-3'>
                            {businessCard.name}
                            &nbsp; | &nbsp;
                            {businessCard.position}
                            <br />
                            {businessCard.description}
                        </div>
                        <a>{businessCard.website ? "🌐": ""}</a>
                        <a href={businessCard.website}>{businessCard.website ? businessCard.website : ""}</a>
                    </Col>
                    <Col xs={6}
                        id='col-photo'
                        className='text-end'
                    >
                        <img
                            id='photo'
                            src={businessCard.photo}
                            width={300}
                            height={300}
                            className={`
                                ${darkMode ? 'border-light' : 'border-dark'}
                                border border-1
                                rounded-circle
                                user-select-none
                            `}
                            alt={`${businessCard.name}`}
                            draggable={false}
                        />
                    </Col>
                </Row>
                <a href={`mailto:${businessCard.email}`}
                    className={`
                        ${darkMode ? 'text-dark' : 'text-light'}
                        text-decoration-none
                    `}
                >
                    <div className='text-center mb-3'>
                        <Button
                            id='contact-button'
                            className='w-100 contact-button'
                            color={`${darkMode ? 'primary-dark' : 'primary'}`}
                        >
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                className='me-2'
                            />
                            Contactar
                        </Button>
                    </div>
                </a>
                {/* vCar Download */}
                <div className='text-center mb-3'>
                    <Button
                        id='vcard-button'
                        className='w-100 contact-button'
                        color={`${darkMode ? 'primary-dark' : 'primary'}`}
                        onClick={downloadVCard}
                    >
                        <FontAwesomeIcon
                            icon={faAddressCard}
                            className='me-2'
                        />
                        Descargar contacto
                    </Button>
                </div>
                <div className='text-center mb-3'>
                    <a href={`tel: +${businessCard.socials.whatsapp?.slice(businessCard.socials.whatsapp.length -12, businessCard.socials.whatsapp.length)}`}>
                        <Button
                            id='call-button'
                            className='w-100 contact-button'
                            color={`${darkMode ? 'primary-dark' : 'primary'}`}
                            //onClick={useHref}
                        >
                            <FontAwesomeIcon
                                icon={faPhone}
                                className='me-2'
                            />
                            Llamar                    
                        </Button>
                    </a>

                </div>

                <div id='socials-container'
                    className='d-flex flex-wrap'
                >
                    {
                        Object.keys(businessCard.socials).map((social, index) => {
                            const socialName = social as SupportedSocials;
                            const socialUrl = businessCard.socials[socialName];

                            if (!socialUrl) return null;

                            return (
                                <ICardSocialMedia
                                    url={socialUrl}
                                    social={socialName}
                                    key={index}
                                />
                            )
                        })
                    }
                </div>

            </Container>
        </Container>
    )
}

export default CardPage;